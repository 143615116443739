import React from 'react';
import { Link } from 'gatsby';
import { GatsbySeo as SEO } from 'gatsby-plugin-next-seo';

import Layout from '@components/Layout/Layout';
import styled from 'styled-components';

const LegalTextWrapper = styled.div`
  margin-top: 60px;

  @media only screen and (max-width: 768px) {
    padding: 0 16px;
    margin-top: 40px;
  }
`;

const ImprintPage = () => (
  <Layout>
    <SEO noindex={true} />
    <LegalTextWrapper>
      <h1>Imprint</h1>
      <h3>Legal disclosure</h3>
      <p>Information in accordance with § 5 TMG:</p>
      <p>
        <b>Sebabytes UG (haftungsbeschränkt)</b>
        <br />
        Lindenberger Str. 38
        <br />
        06712 Schnaudertal OT Dragsdorf
        <br />
        Germany
      </p>
      <p>
        <b>Managing director</b>
        <br />
        Sebastian Bojahr
      </p>
      <p>
        Responsible for the content in accordance with § 55 paragraph 2 RsTV:
        <br /> Sebastian Bojahr
      </p>
      <h3>Trade register</h3>
      <p>
        Handelsregister beim Amtsgericht Charlottenburg <br />
        Number HRB 215332 B
      </p>
      <div style={{ marginTop: '80px', marginBottom: '300px' }}>
        <Link style={{ color: '#000000' }} to="/">
          Back
        </Link>
      </div>
    </LegalTextWrapper>
  </Layout>
);

export default ImprintPage;
